.print-all-badge {
    height: 20px;
    min-width: 20px;
    width: max-content;
    font-size: 12px;
    right: 0px;
    top: -10px;
    border-radius: 15px;
    transform: translate(100%);
    /* transform: translate(50%, -50%); */
    opacity: .90;
}

.table-hover tr {
    cursor: pointer;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: #C2C6FB;
}

:root {
    --toastify-color-info: #39afd1 !important;
    --toastify-color-success: #0acf97 !important;
    --toastify-color-warning: #ffbc00 !important;
    --toastify-color-error: #fa5c7c !important;
}

.single-widget-height {
    height: calc(100vh - 300px);
    ;
    max-height: calc(100vh - 300px);
}

/* .filter-facilities-modal-height{
    max-height: calc(100vh - 260px);
} */

.breadcrumb {
    margin-bottom: 0;
}

.page-loading-loader-div {
    height: calc(100vh - 650px);
}

.facility-card:hover {
    cursor: pointer;
    transition: all .2s ease-in-out;
    transform: scale(1.05);
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.noHover {
    cursor: default;
}

.th-hover {
    cursor: pointer;
}

.react-select__option {
    padding-left: 10px !important;
}

.invalid {
    border-color: #fa5c7c !important;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #fa5c7c;
}

.comment-icon:hover {
    color: #39afd1
}

.med-portal {
    transition: 1s ease-out;
}

.med-portal.closed {
    height: 69px !important;
    /* width: 100%; */
    overflow: hidden;
    /* pointer-events: none; */
    /* cursor: not-allowed !important; */
}

.med-portal.open {
    height: 750px !important;
    width: 100%;
    overflow: hidden;
}

.chatStyle iframe {
    width: 100%;
    height: 100%;
}

.parent-div-mediprocity {
    z-index: 2;
    bottom: 0;
}

body[data-leftbar-compact-mode="condensed"] .parent-div-mediprocity {
    left: 70px;
    right: 0;
}

.parent-div-mediprocity.fullWidth {
    left: 260px;
    right: 0;
}

.parent-div-mediprocity.left {
    width: 700px;
    left: 260px;
}

.parent-div-mediprocity.right {
    width: 700px;
    right: 0;
}

.newRow {
    animation-name: newRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.approvedRow {
    animation-name: approvedRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.approvedOnceRow {
    animation-name: approvedOnceRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.deniedRow {
    animation-name: deniedRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.askedRow {
    animation-name: askedRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.sendRow {
    animation-name: sendRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.doNotSendRow {
    animation-name: doNotSendRowcolorChange;
    animation-duration: 6s;
    animation-timing-function: ease-in;
}

.deletedRow {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 6s, opacity 6s linear;
}

@keyframes newRowcolorChange {
    0% {
        background-color: #F7DD75;
    }
}

@keyframes approvedRowcolorChange {
    0% {
        background-color: #0acf97;
    }
}

@keyframes approvedOnceRowcolorChange {
    0% {
        background-color: #0AC3CF;
    }
}

@keyframes deniedRowcolorChange {
    0% {
        background-color: #fa5c7c;
    }
}

@keyframes askedRowcolorChange {
    0% {
        background-color: #fd7e14;
    }
}

@keyframes sendRowcolorChange {
    0% {
        background-color: #0acf97;
    }
}

@keyframes doNotSendRowcolorChange {
    0% {
        background-color: #fa5c7c;
    }
}

/* .edit-account-field:hover{
    cursor: pointer;
    border-radius: 2px;
    outline: 3px solid #eef2f7;
    outline-offset: 5px;
} */

.docIframe {
    overflow: hidden;
    min-height: calc(100vh - 165px);
    margin-top: 15px;
    border-radius: 8px;
}

.settings-dropdown {
    background-color: transparent;
}

/* .settings-dropdown:hover{
    background-color: #e9ecef;
} */

/* .settings-dropdown:focus{
    background-color: inherit;
} */

.admissionMedsAccordion:hover {
    cursor: pointer;
}

.gradient-modal .close {
    color: white;
    opacity: 0.85;
}

body[data-leftbar-compact-mode="condensed"] .wrapper .left-side-menu {
    position: fixed !important;
}

body[data-leftbar-compact-mode="condensed"] {
    min-height: max-content !important;
}

.elementToFadeInAndOut {
    opacity: 1;
    animation: fade 3s linear;
    animation-iteration-count: infinite;
}

.flashingIndicatorToFadeInAndOut {
    min-height: 12px;
    min-width: 12px;
    border-radius: 20px;
    background: #39afd1;
    opacity: 1;
    animation: fade 3s linear;
    animation-iteration-count: infinite;
}

.flashingIndicatorforFileManager {
    color: #39afd1;
    opacity: 1;
    animation: fade 3s linear;
    animation-iteration-count: infinite;
}

.flashingIndicatorforFileSquare {
    background-color: #39afd1 !important;
    opacity: 1;
    animation: fade 3s linear;
    animation-iteration-count: infinite;
}


@keyframes fade {

    0%,
    100% {
        opacity: 0.5
    }

    50% {
        opacity: 1
    }
}

.file-manager-height {
    height: calc(100vh - 160px);
    ;
    max-height: calc(100vh - 160px);
}

.cancel-hover:hover:focus {
    background: transparent !important;
}

.cancel-hover {
    background: transparent !important;
}

.file-square:hover {
    cursor: pointer;
}

.link-button {
    opacity: 0.75;
}

.link-dropdown-side-button {
    opacity: 0.55;
}

.link-dropdown-side-button:hover {
    opacity: 1;
}

.link-button:hover {
    transition: 0.2s;
    opacity: 1;
}

.files-navigate-scrolling-size {
    max-height: calc(100% - 94px);
}

.files-display-scrolling-size {
    max-height: calc(100% - 105px);
}

.sidebar-folder-item:hover {
    cursor: pointer;
}

.selected-leftside-dropdown {
    /* border-left-color: black;
    outline: 10px solid; */
    background: #eef2f7 !important;
}

.action-button:hover {
    transition: 0.3s;
    color: white;
    cursor: pointer;
}

.edit-file-description:hover {
    cursor: pointer;
}

.col-truncate {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 102px;
    text-transform: capitalize;
}

/* .readRow{
    font-weight: 400;
    background-color: rgba(25, 0, 50, 0.05);
} */

.unreadRow {
    font-weight: bold;
    box-shadow: -3px 0 0px 0px var(--orange);
}

/* .unreadRow:nth-child(4n){
    font-weight: bold;
    box-shadow: -3px 0 0px 0px var(--orange);
} */

/* .faciId-col{
    display: none;
} */

/* .faci-col:hover + .faciId-col{
    display: block;
} */

.refresh-widget-button-rotate {
    animation: rotate-refresh-button 0.6s ease-out;
}

@keyframes rotate-refresh-button {
    from {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-45deg);
        animation-direction: reverse;
    }

    to {
        transform: rotate(45deg);
        animation-direction: normal;
    }
}

.refresh-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.pharm-card:hover {
    cursor: pointer;
    transition: all .1s ease-in-out;
    transform: scale(1.05);
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.alert-th {
    box-shadow: 0 -1px 0 #eef2f7, 0 1px 0 #eef2f7, -3px 0px 0px 0px #eef2f7, -3px -1px 0px 0px #eef2f7;
}

.selectedRecycling {
    background: #6c757d;
    color: white;
    border-radius: 5px;
}

.selectedRecycling:hover {
    background: #6c757d !important;
    color: white !important;
}

.selectedModalFolder {
    background: #BFCEE1;
    color: black;
}

.selectedModalFolder:hover {
    background: #BFCEE1;
    color: black;
}

.no-click {
    pointer-events: none;
}

.enable-click {
    pointer-events: auto;
}

.invalid-select .react-select__control {
    border-color: #fa5c7c !important;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4b4b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4b4b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid {
    border-color: #fa5c7c !important;
    padding-right: 2.25rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4b4b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4b4b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.patient-drug-details .single-drug:nth-child(even) {
    background: linear-gradient(90deg, rgba(238, 238, 238, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 100%);
}

.drug-info-line,
.drug-note {
    font-size: 12px !important;
}

.dea-class {
    font-size: 12px !important;
}

.delivery-status {
    font-size: 11px !important;
    padding-top: 2px;
    padding-bottom: 1px;
}

@page {
    margin: 10mm 3mm;

}


@media print {
    .single-slip {
        page-break-after: always !important;
        font-size: 22px !important;
    }

    .patient-drug-details {
        font-size: 19px !important;
    }

    .single-drug {
        font-size: 16px !important;
    }

    .drug-info-line,
    .drug-note {
        font-size: 16px !important;
    }

    .dea-class {
        border: none;
    }

    .delivery-status {
        font-size: 17px !important;
        font-weight: 700;
    }
}


.alert-drag-cont {
    display: flex;
    touch-action: none;
    width: 100%;
    height: 100%;
    margin: 1rem auto;
}

.alert-dropzone {
    flex: 1;
    height: 300px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

.alert-dropzone.left {
    margin-right: 10px;
}

.alert-grid-item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0.9em;
}

.alert-grid-item-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #727cf5;
    display: flex;
    justify-content: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    border-radius: 0.5em;
}

.fixed-buttons {
  display: flex;
  justify-content: center;
  z-index: 1
}


  .custom-input {
    width: 55px;
    padding: 10px;
  }

  .back-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #646d75;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
